// Sidebar.jsx
import React, { useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faHeadphones, faVideo, faFilePowerpoint } from '@fortawesome/free-solid-svg-icons';
import { SelectedFileContext } from "./SelectedFileContext";
import { BASE_URL } from "../function/Config";

function Sidebar() {
    const { selectedFiles, setSelectedFiles } = useContext(SelectedFileContext);
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [uploadSuccess, setUploadSuccess] = React.useState(false);

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('video', selectedFiles.video.id);
            formData.append('audio', selectedFiles.audio.id);
            formData.append('slide', selectedFiles.slide.id);
            formData.append('speaker', selectedFiles.audio.filename);
            formData.append('videoName', selectedFiles.video.filename);
            formData.append('audioName', selectedFiles.audio.filename);
            formData.append('slideName', selectedFiles.slide.filename);

            // Include the models used
            formData.append('audioModel', selectedFiles.audio.model);
            formData.append('videoModel', selectedFiles.video.model);

            console.log(formData);
            const token = sessionStorage.getItem('Token');
            const response = await fetch(`${BASE_URL}/video-tasks/${params.userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST',
                body: formData,
            });

            const feedback = await response.json();
            console.log(feedback);

            if (response.ok) {
                setUploadSuccess(true);
                // Clear selected files
                setSelectedFiles({ video: null, audio: null, slide: null, model: null });

                // Hide the success message after a few seconds
                setTimeout(() => {
                    setUploadSuccess(false);
                }, 5000);
            } else {
                console.log(feedback.message);
            }
        } catch (error) {
            console.log('Upload error:', error);
        }
    };

    return (
        <>
            {/* Success Message */}
            {uploadSuccess && (
                <div
                    id="toast-success"
                    style={{
                        width: '80%',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                    className="fixed top-4 right-4 z-50 flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow"
                    role="alert"
                >
                    <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg">
                        <svg
                            className="w-5 h-5"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 4.707 7.293a1 1 0 10-1.414 1.414L9 14.414l7.707-7.707a1 1 0 000-1.414z" />
                        </svg>
                        <span className="sr-only">Success icon</span>
                    </div>
                    <div className="ml-3 text-sm font-normal">Task created successfully.</div>
                    <button
                        onClick={() => setUploadSuccess(false)}
                        type="button"
                        className="ml-auto bg-white text-gray-400 hover:text-gray-900 rounded-lg p-1.5 hover:bg-gray-100"
                        aria-label="Close"
                    >
                        <span className="sr-only">Close</span>
                        <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                        >
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1l12 12M13 1L1 13"
                            />
                        </svg>
                    </button>
                </div>
            )}

            {/* Sidebar Component */}
            <aside
                id="logo-sidebar"
                className="fixed top-0 left-0 w-64 h-screen pt-20 transition-transform transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700"
                aria-label="Sidebar"
            >
                <div className="flex flex-col h-full px-2 pb-4 overflow-y-auto">
                    <div className="mb-4">
                        <ul className="space-y-[0.35rem] mt-3">
                            {/* Section A */}
                            <li className="font-bold text-lg">Voice Model</li>
                            <li
                                onClick={() => history.push(`/audio/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/audio/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                My Voice Model
                            </li>
                            {/* <li
                                onClick={() => history.push(`/uploadaudio/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/uploadaudio/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Upload Reference Audio
                            </li> */}
                            <li
                                onClick={() => history.push(`/newaudio/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/newaudio/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Create Voice Model
                            </li>
                            <div className="border-b border-gray-200 my-4"></div>

                            {/* Section B */}
                            <li className="font-bold text-lg">Talking-head Model</li>
                            <li
                                onClick={() => history.push(`/video/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/video/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                My Talking-head Model
                            </li>
                            {/* <li
                                onClick={() => history.push(`/uploadvideo/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/uploadvideo/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Upload Talking-head Video
                            </li> */}
                            <li
                                onClick={() => history.push(`/newvideo/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/newvideo/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Create Talking-head Model
                            </li>
                            <div className="border-b border-gray-200 my-4"></div>

                            {/* Section C */}
                            <li className="font-bold text-lg">Slides</li>
                            <li
                                onClick={() => history.push(`/slide/${params.userId}`)}
                                className={`ml-4 text-base ${
                                    isActive('/slide/') ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                My Slides
                            </li>
                            <div className="border-b border-gray-200 my-4"></div>

                            {/* Section D */}
                            <li className="font-bold text-lg">History</li>
                            <li
                                onClick={() => history.push(`/history/${params.userId}/audio`)}
                                className={`ml-4 text-base ${
                                    isActive(`/history/${params.userId}/audio`) ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Voice Model Task
                            </li>
                            <li
                                onClick={() => history.push(`/history/${params.userId}/avatar`)}
                                className={`ml-4 text-base ${
                                    isActive(`/history/${params.userId}/avatar`) ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Talking-head Model Task
                            </li>
                            <li
                                onClick={() => history.push(`/history/${params.userId}/video`)}
                                className={`ml-4 text-base ${
                                    isActive(`/history/${params.userId}/video`) ? 'text-orange-500' : 'text-gray-600'
                                } hover:text-blue-500 cursor-pointer`}
                            >
                                Lecture Video Task
                            </li>
                            <div className="border-b border-gray-200 my-4"></div>
                        </ul>
                    </div>

                    <div className="flex justify-center items-center mt-2">
                        <ul className="space-y-2">
                            <li className="text-center">
                                <FontAwesomeIcon icon={faClipboard} />
                                <span className="ml-2 truncate">Lecture Video Task</span>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-6">
                        <ul>
                            <li className="mt-2 ml-4">
                                <FontAwesomeIcon icon={faHeadphones} />
                                <span className="ml-2 truncate">
                                    {selectedFiles.audio ? selectedFiles.audio.filename : ''}
                                </span>
                            </li>
                            <li className="mt-2 ml-4">
                                <FontAwesomeIcon icon={faVideo} />
                                <span className="ml-2 truncate">
                                    {selectedFiles.video ? selectedFiles.video.filename : ''}
                                </span>
                            </li>
                            <li className="mt-2 ml-4">
                                <FontAwesomeIcon icon={faFilePowerpoint} />
                                <span className="ml-2 truncate">
                                    {selectedFiles.slide ? selectedFiles.slide.filename : ''}
                                </span>
                            </li>
                            <div className="mt-4 flex items-center justify-center">
                                <button
                                    className={`px-3 py-1.5 text-sm font-medium rounded-md shadow transition duration-300 ease-in-out ${
                                        selectedFiles.video && selectedFiles.audio && selectedFiles.slide
                                            ? 'bg-blue-500 hover:bg-blue-600 text-white hover:shadow-md'
                                            : 'bg-gray-400 text-gray-200 cursor-not-allowed'
                                    }`}
                                    onClick={handleSubmit}
                                    disabled={
                                        !selectedFiles.video || !selectedFiles.audio || !selectedFiles.slide
                                    }
                                >
                                    Submit Task
                                </button>
                            </div>
                        </ul>
                    </div>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;
