import { useHistory } from 'react-router-dom';

export default function Homecontent() {
    const history = useHistory();
    const handleClick = () => {
        history.push('/signin');
    };
    return (
        <section className="py-4 bg-white dark:bg-gray-900 lg:pt-12 lg:pb-16 mt-14">
            <div className="px-4 mx-auto max-w-4xl text-center">
                <h1 className="text-4xl font-bold tracking-tight mb-8 text-gray-900 lg:font-bold lg:text-5xl lg:leading-none dark:text-white lg:text-center ">Expand your reach with our AI video generator</h1>
                <button onClick={handleClick} type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Get started
                </button>
                <div className="relative w-full max-w-4xl mx-auto mt-12 overflow-hidden border border-gray-300 shadow-2xl rounded-3xl lg:dark:border-gray-700 pt-[56.25%]">
                    <iframe
                        title="YouTube video player"
                        src="https://www.youtube.com/embed/vumo602EzlE?si=ayh_YeZekWgCOi8w" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        className="absolute top-0 left-0 w-full h-full"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </section>


    )

}

