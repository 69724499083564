// AudioTasksHistory.jsx
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import SystemError from "../components/SystemError";
import { BASE_URL } from "../function/Config";

export default function AudioTasksHistory() {
    const history = useHistory();
    const { userId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [audioTasks, setAudioTasks] = useState([]);

    const fetchAudioTasks = () => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        const url = `${BASE_URL}/audio-tasks/${userId}/all`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAudioTasks(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    };

    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString('en-CA');
    };

    useEffect(() => {
        fetchAudioTasks();
    }, []);

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            <div className="p-4 sm:ml-64">
                <div className="flex flex-wrap justify-center items-center mt-20">
                    <div className="relative overflow-x-auto shadow-md w-full max-w-4xl mx-auto">
                        <h2 className="text-2xl font-bold mb-4 text-center">Voice Model Tasks History</h2>
                        <table className="w-full text-sm text-center text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Speaker</th>
                                    <th scope="col" className="px-6 py-3">Language</th>
                                    <th scope="col" className="px-6 py-3">Model</th>
                                    <th scope="col" className="px-6 py-3">Status</th>
                                    <th scope="col" className="px-6 py-3">Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {audioTasks.map(task => (
                                    <tr key={task.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-6 py-4">{task.filename}</td>
                                        <td className="px-6 py-4">{task.language}</td>
                                        <td className="px-6 py-4">{task.model}</td>
                                        <td className="px-6 py-4">
                                            <span className={`font-medium ${task.status === 'done' ? 'text-green-600' : 'text-red-600'}`}>
                                                {task.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4">{formatDate(task.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {isLoading && (
                            <div className="text-center mt-4">Loading...</div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
