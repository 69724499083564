import {useHistory, useParams} from "react-router-dom";
import React, {useState, useEffect, useContext} from "react";
import Home_Navbar from "../components/Home_Navbar";
import {SelectedFileContext} from '../components/SelectedFileContext';
import Sidebar from "../components/Sidebar";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faCircle, faHeadphones, faVideo} from '@fortawesome/free-solid-svg-icons';
import {BASE_URL} from "../function/Config";
import SystemError from "../components/SystemError";
export default function Video() {

    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const params = useParams();

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [files, setFiles] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 5;

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [iframeSrc, setIframeSrc] = useState('');
    const {selectedFiles, selectFile} = useContext(SelectedFileContext);
    const [modelFilter, setModelFilter] = useState('All');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const select = (file) => {
        if (selectedFiles?.video?.id === file.id) {
            selectFile('video', null);
            setSelectedFile(null);
        } else {
            selectFile('video', file);
            setSelectedFile(file);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        let url = `${BASE_URL}/avatar-tasks/${params.userId}/done`;
        if (modelFilter !== 'All') {
            url += `?model=${modelFilter}`;
        }

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFiles(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    }, [params.userId, uploadSuccess, modelFilter]); // 添加 modelFilter 作为依赖

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const filters = ['All', 'Fast Talking-head Model', 'High-Resolution Talking-head Model', 'Gaussian Splatter Talking-head Model', 'Gaussian Splatter Talking-head Full-Body Model', 'NERF Talking-head Model'];
    const handleFileClick = (fileUrl) => {
        setIframeSrc(fileUrl);
    };

    return (
        <section>
            <Home_Navbar/>
            <Sidebar/>
            {showModal && <SystemError onClose={() => setShowModal(false)}/>}
            <div className="p-4 sm:ml-64">
                {isLoading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 dark:bg-gray-800">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    </div>

                ) : (
                    <div className="mx-auto mt-8">
                        {uploadSuccess && (
                            <div id="toast-success"
                                style={{
                                    width: '80%',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}
                                 className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                 role="alert">
                                <div
                                    className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                         fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    <span className="sr-only">Check icon</span>
                                </div>
                                <div className="ms-3 text-sm font-normal">{selectedFile.name} upload successfully.</div>
                                <button onClick={() => setUploadSuccess(false)} type="button"
                                        className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                        data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                         fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                    </svg>
                                </button>
                            </div>)}
                        <div className="flex flex-col lg:flex-row justify-center items-center gap-5 mt-20">
                            <div className="w-full lg:w-2/5 flex flex-col bg-white rounded-lg shadow-lg p-5 dark:bg-gray-800 dark:border-gray-700">
                                {/* File List Section */}
                                <h2 className="text-xl text-center font-semibold text-gray-800 dark:text-white mb-6">My Talking-head Model
                                </h2>

                                <div className="overflow-auto min-h-[500px]">
                                    {/* Filter Buttons */}
                                    <div className="flex justify-center mb-4">
                                        <div className="relative mr-4">
                                            <button onClick={toggleDropdown} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                                                {modelFilter} Models <svg className="w-2.5 h-2.5 ml-3" aria-hidden="true" fill="none" viewBox="0 0 10 6">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                                            </svg>
                                            </button>
                                            {dropdownOpen && (
                                                <div className="absolute z-10 w-48 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 mt-2">
                                                    <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                                                        {filters.map(filter => (
                                                            <li key={filter}>
                                                                <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                                    <input id={`default-radio-${filter}`} type="radio" value={filter} name="default-radio" checked={modelFilter === filter} onChange={() => setModelFilter(filter)} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"/>
                                                                    <label htmlFor={`default-radio-${filter}`} className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300">
                                                                        {filter}
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* File List */}
                                    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {currentFiles.map((file) => (
                                            <li key={file.uuid} className="py-3 sm:py-4 hover:bg-gray-100 dark:hover:bg-gray-700">
                                                <div className="flex items-center justify-between">
                                                    <button onClick={() => handleFileClick(`${BASE_URL}/avatar-tasks/${file.id}`)} className="hover:text-blue-500">
                                                        <FontAwesomeIcon icon={faVideo}/>
                                                        <span className="ml-2 truncate" style={{maxWidth: '150px'}} title={file.filename}>
                                    {file.filename.length > 15 ? `${file.filename.substring(0, 15)}...` : file.filename}
                                </span>
                                                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3">
                            {file.model}
                        </span>
                                                    </button>
                                                    <button onClick={() => select(file)} className="text-indigo-600 hover:text-indigo-800">
                                                        <FontAwesomeIcon icon={selectedFile === file || selectedFiles?.video?.id === file.id ? faCheckCircle : faCircle} className={selectedFile === file || selectedFiles?.video?.id === file.id ? "text-green-500" : "text-gray-400"}/>
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Pagination */}
                                <div className="pagination flex justify-center mt-4">
                                    <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="mx-2 px-4 py-2 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50">Previous</button>
                                    <p className="text-sm font-medium text-gray-900 dark:text-white mx-2 my-auto">{currentPage}</p>
                                    <button onClick={() => paginate(currentPage + 1)} disabled={currentPage >= Math.ceil(files.length / filesPerPage)} className="mx-2 px-4 py-2 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50">Next</button>
                                </div>
                            </div>

                            <div className="flex-grow flex flex-col w-full text-center lg:w-3/5">
                                {/* <div className="flex-grow bg-white rounded-lg shadow-lg p-5 dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Talking-head Dashboard</h2>
                                    <p className="text-gray-600 dark:text-white mb-4">
                                        ← This area showcases the voices cloned using AI models.
                                    </p>
                                </div> */}
                                <div className="flex-grow rounded-lg shadow-lg overflow-hidden mb-4">
                                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Talking-head Player</h2>
                                    <p>Click on a filename on the left to play the video.</p>
                                    <iframe src={iframeSrc} title="Video Preview" className="w-full h-[400px]" allow="autoplay; encrypted-media" loading="lazy"></iframe>
                                </div>

                                {/* <div className="flex-grow bg-white rounded-lg shadow-lg p-5 dark:bg-gray-800 dark:border-gray-700 mb-4">
                                    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">New Talking-head</h2>
                                    <p className="text-gray-600 dark:text-white mb-4">
                                        If you haven't created a Talking-head yet,
                                        you can do so in the 'New Talking-head' section below.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>)}
            </div>
        </section>
    )
}