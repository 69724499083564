import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Homepage from './pages/Homepage';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Dashboard from "./pages/Dashboard";
import Video from "./pages/Video";
import Audio from "./pages/Audio";
import Slide from "./pages/Slide";
import NewAudio from "./pages/NewAudio";
import UploadAudio from "./pages/UploadAudio";
import UploadVideo from "./pages/UploadVideo";
import NewVideo from "./pages/NewVideo";
import {SelectedFileProvider} from "./components/SelectedFileContext";
import About from "./pages/About";
import Contact from "./pages/Contact";
import History from "./pages/History";
import VideoTasksHistory from "./pages/VideoTasksHistory";
import AudioTasksHistory from "./pages/AudioTasksHistory";
import AvatarTasksHistory from "./pages/AvatarTasksHistory";

// Todo 如果没有token 或者token报错要处理的

function App() {
    return (
        <SelectedFileProvider>
            <Router>
                <Switch>
                    <Route exact path="/" component={Homepage}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route path="/signin" component={Signin}/>
                    <Route path="/signup" component={Signup}/>
                    <Route path="/dashboard/:userId" component={Dashboard}/>
                    <Route path="/video/:userId" component={Video}/>
                    <Route path="/slide/:userId" component={Slide}/>
                    <Route path="/audio/:userId" component={Audio}/>
                    <Route path="/uploadaudio/:userId" component={UploadAudio}/>
                    <Route path="/uploadvideo/:userId" component={UploadVideo}/>
                    <Route path="/newaudio/:userId" component={NewAudio}/>
                    <Route path="/newvideo/:userId" component={NewVideo}/>
                    {/* <Route path="/history/:userId" component={History}/> */}
                    <Route path="/history/:userId/video" component={VideoTasksHistory}/>
                    <Route path="/history/:userId/audio" component={AudioTasksHistory}/>
                    <Route path="/history/:userId/avatar" component={AvatarTasksHistory}/>
                </Switch>
            </Router>
        </SelectedFileProvider>
    );
}

export default App;



