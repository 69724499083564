// Slide.jsx
import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import { SelectedFileContext } from "../components/SelectedFileContext";
import { BASE_URL } from "../function/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import SystemError from "../components/SystemError";
import Dropzone from "../components/Dropzone";

export default function Slide() {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const params = useParams();

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Upload progress
    const [uploadError, setUploadError] = useState(null);    // Upload error
    const [fileSizeError, setFileSizeError] = useState(null); // File size error

    const [files, setFiles] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const filesPerPage = 5;

    const indexOfLastFile = currentPage * filesPerPage;
    const indexOfFirstFile = indexOfLastFile - filesPerPage;
    const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [iframeSrc, setIframeSrc] = useState('');
    const { selectedFiles, selectFile } = useContext(SelectedFileContext);
    const [response, setResponse] = useState({});

    const handleFileClick = (fileUrl) => {
        setIframeSrc(fileUrl);
    };

    const select = (file) => {
        if (selectedFiles?.slide?.id === file.id) {
            selectFile('slide', null);
            setSelectedFile(null);
        } else {
            selectFile('slide', file);
            setSelectedFile(file);
        }
    };

    const fetchFiles = () => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/slides/${params.userId}/slide`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setFiles(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchFiles();
    }, [params.userId]);

    const handleDropzoneResponse = (response) => {
        setResponse(response);
        if (response.error) {
            console.error('Upload error:', response.error);
            setUploadError(response.error);
            setUploadSuccess(false);
        } else {
            setUploadError(null);
            setUploadSuccess(true);
            setUploadProgress(0);
            fetchFiles(); // Refresh file list after successful upload
        }
    };

    // Close the success message after 3 seconds
    useEffect(() => {
        if (uploadSuccess) {
            const timer = setTimeout(() => {
                setUploadSuccess(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [uploadSuccess]);

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            <div className="p-4 sm:ml-64">
                {isLoading ? (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-50 dark:bg-gray-800">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
                        </div>
                    </div>
                ) : (
                    <div className="mx-auto mt-8">
                        {uploadSuccess && (
                            <div id="toast-success"
                                style={{
                                    width: '80%',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }}
                                className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
                                role="alert">
                                <div
                                    className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                    <span className="sr-only">Check icon</span>
                                </div>
                                <div className="ms-3 text-sm font-normal">Upload successful.</div>
                                <button onClick={() => setUploadSuccess(false)} type="button"
                                    className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                                    data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                </button>
                            </div>
                        )}

                        {/* Display upload error */}
                        {uploadError && (
                            <div className="text-red-500 text-center mb-4">
                                {uploadError}
                            </div>
                        )}

                        <div className="flex flex-row justify-center mt-20">
                            <div
                                className="w-2/5 flex flex-col min-h-[500px] bg-white border border-gray-200 shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                                <h2 className="text-xl text-center font-semibold text-gray-800 dark:text-white mb-6">Slides List</h2>
                                <ul role="list" className="flex-grow overflow-auto divide-y divide-gray-200 dark:divide-gray-700">
                                    {currentFiles.map((file, index) => (
                                        <li key={file.uuid}
                                            className="py-3 sm:py-4 hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <div className="flex items-center justify-between">
                                                <button
                                                    onClick={() => handleFileClick(`${BASE_URL}/slides/${file.id}`)}
                                                    className="hover:text-blue-500">
                                                    <FontAwesomeIcon icon={faFilePowerpoint} />
                                                    <span className="ml-2 truncate" style={{ maxWidth: '150px' }}
                                                        title={file.filename}>
                                                        {file.filename.length > 15 ? `${file.filename.substring(0, 15)}...` : file.filename}
                                                    </span>
                                                </button>
                                                <button onClick={() => select(file)}
                                                    className="ml-2 ">
                                                    {(selectedFile === file || selectedFiles?.slide?.id === file.id)
                                                        ? <FontAwesomeIcon icon={faCheckCircle}
                                                            className="text-green-500" />
                                                        : <FontAwesomeIcon icon={faCircle} className="text-gray-400" />
                                                    }
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="pagination flex justify-center mt-4">
                                    <button
                                        onClick={() => paginate(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className="mx-2 px-4 py-2 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50"
                                    >
                                        Previous
                                    </button>
                                    <p className="text-sm font-medium text-gray-900 dark:text-white mx-2 my-auto">{currentPage}</p>
                                    <button
                                        onClick={() => paginate(currentPage + 1)}
                                        disabled={currentPage >= Math.ceil(files.length / filesPerPage)}
                                        className="mx-2 px-4 py-2 border rounded text-sm font-medium text-gray-900 dark:text-white disabled:opacity-50"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                            <div className="w-full text-center lg:w-3/5 flex flex-col justify-between bg-white border border-gray-200 shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex-grow flex flex-col items-center justify-center">
                                    <div className="bg-white rounded-lg shadow-lg p-5 dark:bg-gray-800 dark:border-gray-700 mb-4">
                                        <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">Upload Slides</h2>
                                        <p className="text-gray-600 dark:text-white mb-4">
                                        You can view the slides you have uploaded in the left panel. If you need to upload slides, please use the window below.
                                        </p>
                                        <p className="text-gray-600 dark:text-white mb-4">
    Please ensure that each page of the uploaded slides includes <span className="font-bold">notes</span>, as we generate lecture videos based on them.
</p>
                                    </div>

                                    <div className="bg-white shadow-md rounded-lg p-6 flex flex-col items-center justify-center max-w-md">
                                        <h2 className="font-semibold text-lg mb-4">Upload File</h2>
                                        <Dropzone
                                            onResponse={handleDropzoneResponse}
                                            onProgress={setUploadProgress} // Pass the function to receive upload progress
                                            instructionText="Drag & drop files here. (Maximum file size: 20 MB; Supported file format: .pptx)"
                                            accept={{
                                                'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx']
                                            }}
                                            maxSize={20 * 1024 * 1024} // 20 MB in bytes
                                            className="flex justify-center items-center p-10 border-dashed border-2 border-neutral-300 rounded-lg cursor-pointer hover:border-neutral-400 transition duration-300 ease-in-out w-full"
                                        />
                                    </div>
                                </div>

                                {/* Placeholder area */}
                                <div className="w-full lg:w-3/5 rounded-lg overflow-hidden mt-4">
                                    <iframe src={iframeSrc} title="Slide Preview" className="w-full h-[400px]"
                                        allow="autoplay; encrypted-media" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}
