// NewVideo.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Home_Navbar from "../components/Home_Navbar";
import { useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ModelCard from "../components/ModelCard";
import Dropzone from "../components/Dropzone"; // Import Dropzone for file uploads
import { BASE_URL } from "../function/Config";

// Tooltip Component
const Tooltip = ({ tooltipText }) => {
    const [visible, setVisible] = useState(false);
    const tooltipRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <span className="relative inline-block ml-1">
            <span
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                className="inline-flex items-center justify-center w-4 h-4 bg-gray-300 text-gray-800 rounded-full cursor-pointer text-xs font-bold"
                aria-label="Information"
            >
                !
            </span>
            {visible && (
                <div
                    ref={tooltipRef}
                    className="absolute z-10 p-2 mt-1 text-white bg-gray-700 rounded-md shadow-lg overflow-y-auto max-h-6xl"
                >
                    <div className="space-y-2">
                        {tooltipText.map((line, index) => (
                            <div key={index} className="flex items-start space-x-2 leading-[1.2]">
                                <span className="flex-shrink-0 w-2 h-2 bg-white rounded-full mt-1"></span>
                                <span>{line}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </span>
    );
};

export default function NewVideo() {
    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();
    const params = useParams();

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [originalFile, setOriginalFile] = useState('');
    const [filelists, setFilelists] = useState([]);

    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
    const [showExperimentalModels, setShowExperimentalModels] = useState(false);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 4;

    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileSizeError, setFileSizeError] = useState(null);

    const language = watch('language');
    const speakerName = watch('speakerName');

    useEffect(() => {
        if (uploadSuccess) {
            setCurrentStep(1);
            setSelectedPlan(null);
            reset();
            setOriginalFile('');
        }
    }, [uploadSuccess, reset]);

    useEffect(() => {
        if (uploadSuccess) {
            const timer = setTimeout(() => {
                setUploadSuccess(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [uploadSuccess]);

    useEffect(() => {
        if (fileUploadSuccess) {
            const timer = setTimeout(() => {
                setFileUploadSuccess(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [fileUploadSuccess]);

    // Fetch file list when userId changes
    useEffect(() => {
        if (params.userId) {
            fetchFileList();
        }
    }, [params.userId]);

    // Fetch file list when uploadedFileName changes
    useEffect(() => {
        if (uploadedFileName !== null) {
            // Delay to allow backend to process the new file
            setTimeout(() => {
                fetchFileList();
            }, 500);
        }
    }, [uploadedFileName]);

    const fetchFileList = () => {
        const token = sessionStorage.getItem('Token');
        fetch(`${BASE_URL}/videos/${params.userId}/video`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
            .then(data => {
                setFilelists(data);
                console.log('Fetched Video File List:', data);
                if (uploadedFileName) {
                    const uploadedFile = data.find(file => file.filename.trim().toLowerCase() === uploadedFileName.trim().toLowerCase());
                    console.log('Uploaded Video File:', uploadedFile);
                    if (uploadedFile) {
                        setOriginalFile(uploadedFile.id.toString());
                    }
                    setUploadedFileName(null);
                }
            })
            .catch(error => {
                console.error('Fetch error:', error);
            });
    };

    const onSubmit = async (data) => {
        console.log("Submitting form with data:", data);

        const processedSpeakerName = data.speakerName.replace(/[\s\-]/g, '_');

        const payload = {
            fileId: originalFile,
            language: data.language,
            speakerName: processedSpeakerName,
            id: params.userId,
            modelName: selectedPlan.title,
        };

        console.log("Payload prepared:", payload);

        try {
            const token = sessionStorage.getItem('Token');
            console.log("Sending request to server...");
            const response = await fetch(`${BASE_URL}/avatar-tasks/${params.userId}/new_task`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(payload),
            });

            const feedback = await response.json();
            console.log("Response from server:", feedback);

            if (feedback.message === "Task created successfully") {
                setUploadSuccess(true);
                // Reset steps and selections
                setCurrentStep(1);
                setSelectedPlan(null);
                reset();
                setOriginalFile('');
            } else {
                console.log("Failed to upload:", feedback.message);
            }
        } catch (error) {
            console.log('Upload error:', error);
        }
    };

    // Define the models with tooltips for each feature
    const commonModels = [
        {
            id: 4,
            title: "Gaussian Splatter Talking-head Full-Body Model",
            features: [
                {
                    text: "Video Naturalness: ★★★★☆",
                    tooltip: ""
                },
                {
                    text: "Training Time: 30 Minute",
                    tooltip: "Requires 30 minutes of training before use."
                },
                {
                    text: "Synthesizing Speed: 0.6",
                    tooltip: "Approximately 100 seconds to synthesize 60 seconds of video."
                },
                {
                    text: "Email Notification: Yes",
                    tooltip: "You will get an email notification after the task completes."
                }
            ]
        },
        {
            id: 5,
            title: "NERF Talking-head Model",
            features: [
                {
                    text: "Video Naturalness: ★★★★★",
                    tooltip: "Extremely natural-looking synthesized video."
                },
                {
                    text: "Training Time: 60 Minute",
                    tooltip: "Requires 60 minutes of training before use."
                },
                {
                    text: "Synthesizing Speed: 0.6",
                    tooltip: "Approximately 100 seconds to synthesize 60 seconds of video."
                },
                {
                    text: "Email Notification: Yes",
                    tooltip: "You will get an email notification after the task completes."
                }
            ]
        }
    ];

    const experimentalModels = [
        {
            id: 1,
            title: "Fast Talking-head Model",
            features: [
                {
                    text: "Video Naturalness: ★★☆☆☆",
                    tooltip: "Lower naturalness compared to standard models."
                },
                {
                    text: "Training Time: 0 Minute",
                    tooltip: "No training time required; ready to use instantly."
                },
                {
                    text: "Synthesizing Speed: 1",
                    tooltip: "Approximately 100 seconds to synthesize 100 seconds of video."
                },
                {
                    text: "Email Notification: Yes",
                    tooltip: "You will get an email notification after the task completes."
                }
            ]
        },
        {
            id: 2,
            title: "High-Resolution Talking-head Model",
            features: [
                {
                    text: "Video Naturalness: ★★★☆☆",
                    tooltip: "Moderate naturalness with higher resolution output."
                },
                {
                    text: "Training Time: 0 Minute",
                    tooltip: "No training time required; ready to use instantly."
                },
                {
                    text: "Synthesizing Speed: 0.2",
                    tooltip: "Approximately 100 seconds to synthesize 20 seconds of video."
                },
                {
                    text: "Email Notification: Yes",
                    tooltip: "You will get an email notification after the task completes."
                }
            ]
        },
        {
            id: 3,
            title: "Gaussian Splatter Talking-head Model",
            features: [
                {
                    text: "Video Naturalness: ★★★★☆",
                    tooltip: "High naturalness with Gaussian splatter effects."
                },
                {
                    text: "Training Time: 30 Minute",
                    tooltip: "Requires 30 minutes of training before use."
                },
                {
                    text: "Synthesizing Speed: 0.6",
                    tooltip: "Approximately 100 seconds to synthesize 60 seconds of video."
                },
                {
                    text: "Email Notification: Yes",
                    tooltip: "You will get an email notification after the task completes."
                }
            ]
        }
    ];

    const handleChange = (event) => {
        const selected = event.target.value;
        setOriginalFile(selected);
    };

    const handleDropzoneResponse = (response) => {
        console.log('Upload response:', response);
        if (response.error) {
            setFileSizeError(response.error);
            setFileUploadSuccess(false);
        } else {
            setFileSizeError(null);
            setFileUploadSuccess(true);
            setUploadProgress(0);
            // Store the uploaded filename
            let newUploadedFileName = '';

            // Check if response contains the filename
            if (response.files && response.files.length > 0) {
                newUploadedFileName = response.files[0].filename;
            } else if (response.filename) {
                newUploadedFileName = response.filename;
            } else if (response.file && response.file.filename) {
                newUploadedFileName = response.file.filename;
            } else {
                console.error('Filename not found in response:', response);
                return;
            }

            console.log('Uploaded file name:', newUploadedFileName);
            setUploadedFileName(newUploadedFileName);
        }
    };

    const proceedToNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const selectModel = (model) => {
        setSelectedPlan(model);
        setCurrentStep(2);
    };

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            <div className="p-4 sm:ml-64">
                <div className="mx-auto mt-8">
                    {uploadSuccess && (
                        <div id="toast-success"
                            style={{
                                width: '80%',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            }}
                            className="fixed top-25 right-1 z-50 flex w-full max-w-xs p-4 mb-4 rounded-lg shadow"
                            role="alert">
                            <div
                                className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-white bg-green-500 rounded-lg">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                        d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 5.707 8.293a1 1 0 00-1.414 1.414L9 14.414l8.707-8.707a1 1 0 000-1.414z" />
                                </svg>
                                <span className="sr-only">Check icon</span>
                            </div>
                            <div className="ml-3 text-sm font-normal"> Task created successfully.</div>
                            <button onClick={() => setUploadSuccess(false)} type="button"
                                className="ml-auto -mx-1.5 -my-1.5 bg-green-500 text-white hover:text-gray-200 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-green-600 inline-flex items-center justify-center h-8 w-8"
                                data-dismiss-target="#toast-success" aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" d="M1 1l12 12M13 1L1 13" />
                                </svg>
                            </button>
                        </div>
                    )}
                    {fileUploadSuccess && (
                        <div className="text-green-500 mb-4">
                            File uploaded successfully.
                        </div>
                    )}
                </div>

                <div className="flex flex-col items-center">
                    {/* Step Indicator */}
                    <div className="w-full max-w-3xl mt-10">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${(currentStep / totalSteps) * 100}%` }}></div>
                        </div>
                        <div className="flex justify-between text-sm mt-2">
                            <span>Step {currentStep}</span>
                            <span>{totalSteps} Steps</span>
                        </div>
                    </div>

                    {/* Display Selected Information */}
                    {selectedPlan && (
                        <div className="w-full max-w-3xl mt-6 bg-white p-4 rounded-lg shadow-md">
                            <h3 className="text-lg font-semibold mb-2">Selected Model: {selectedPlan.title}</h3>
                            {language && (
                                <p><strong>Language:</strong> {language}</p>
                            )}
                            {speakerName && (
                                <p><strong>Speaker Name:</strong> {speakerName}</p>
                            )}
                        </div>
                    )}

                    {/* Steps */}
                    {currentStep === 1 && (
                        <>
                            <p className="text-4xl font-bold text-center mt-10">Please choose the base model you want to use:</p>

                            {/* Common Models */}
                            <div className="flex justify-center items-center mt-10">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 mx-auto">
                                    {commonModels.map(model => (
                                        <ModelCard
                                            key={model.id}
                                            title={model.title}
                                            features={model.features}
                                            onChoose={() => selectModel(model)}
                                        />
                                    ))}
                                </div>
                            </div>

                            {/* Toggle Button for Experimental Models */}
                            <div className="flex justify-center mt-10">
                                <button
                                    onClick={() => setShowExperimentalModels(!showExperimentalModels)}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                                >
                                    {showExperimentalModels ? "Hide Experimental/Deprecated Models" : "Show Experimental/Deprecated Models"}
                                </button>
                            </div>

                            {/* Experimental Models */}
                            {showExperimentalModels && (
                                <div className="flex justify-center items-center mt-10">
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 mx-auto">
                                        {experimentalModels.map(model => (
                                            <ModelCard
                                                key={model.id}
                                                title={model.title}
                                                features={model.features}
                                                onChoose={() => selectModel(model)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {currentStep > 1 && (
                        <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-3xl mt-10 bg-white p-6 rounded-lg shadow-md">
                            {/* Step 2 */}
                            {currentStep === 2 && (
                                <div>
                                    <h3 className="text-lg font-semibold mb-4">Step 2: Please select the language</h3>
                                    {errors.language &&
                                        <p className="text-red-600">{errors.language.message}</p>}
                                    <select {...register("language", { required: "This field is required" })}
                                        className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline">
                                        <option value="">Select Language</option>
                                        {['English'].map((lang) => (
                                            <option key={lang} value={lang}>{lang}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {/* Step 3 */}
                            {currentStep === 3 && (
                                <div>
                                    <h3 className="text-lg font-semibold mb-4">Step 3: Please enter the name of the speaker</h3>
                                    {errors.speakerName &&
                                        <p className="text-red-600">{errors.speakerName.message}</p>}
                                    <input {...register("speakerName", { required: "This field is required" })}
                                        className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        type="text" />
                                </div>
                            )}
                            {/* Step 4 */}
                            {currentStep === 4 && (
                                <div>
                                    <h3 className="text-lg font-semibold mb-4">Step 4: Please select video file</h3>
                                    {fileSizeError && (
                                        <div className="text-red-500 mb-2">
                                            {fileSizeError}
                                        </div>
                                    )}
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-bold text-gray-700">
                                            Select an existing file:
                                        </label>
                                        <select
                                            value={originalFile}
                                            onChange={handleChange}
                                            className="w-full px-3 py-2 mb-4 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                        >
                                            {filelists.length > 0 ? (
                                                <>
                                                    <option value="">Select a file</option>
                                                    {filelists.map((file, index) => (
                                                        <option key={index} value={file.id}>
                                                            {file.filename}
                                                        </option>
                                                    ))}
                                                </>
                                            ) : (
                                                <option value="">None</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block mb-2 text-sm font-bold text-gray-700">
                                            Or upload a new file (Please make sure it meets the specifications:
                                            <Tooltip tooltipText={[
                                                "Each frame contains your face, with no other person's face present.",
                                                "Contains only your speech in the video.",
                                                "Your face is roughly centered in the frame.",
                                                "The background won’t affect the quality of the synthesized video.",
                                                "Normal lighting conditions.",
                                                "Keeping face the camera directly."
                                            ]} />
                                            ):
                                        </label>
                                        {/* 添加示例链接 */}
                                        <p className="mt-2 text-sm text-blue-500">
                                            Here is an good talking-head video example:{" "}
                                            <a
                                                href="https://www.youtube.com/watch?v=BxeqIF2PuXQ"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="underline"
                                            >
                                                https://www.youtube.com/watch?v=BxeqIF2PuXQ
                                            </a>
                                        </p>
                                        <div className="bg-white shadow-md rounded-lg p-6 w-full">
                                            <Dropzone
                                                onResponse={handleDropzoneResponse}
                                                onProgress={setUploadProgress} // Pass the progress update function
                                                maxSize={250 * 1024 * 1024} // Adjust max size as needed
                                                instructionText="Drag & drop files here. (Maximum file size: 250 MB; Supported file formats: .mp4, .mov, .avi)"
                                                accept={{
                                                    'video/mp4': ['.mp4'],
                                                    'video/quicktime': ['.mov'],
                                                    'video/x-msvideo': ['.avi']
                                                }}
                                                className="flex justify-center items-center p-10 border-dashed border-2 border-neutral-300 rounded-lg cursor-pointer hover:border-neutral-400 transition duration-300 ease-in-out"
                                            />
                                            {uploadProgress > 0 && (
                                                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-4">
                                                    <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Navigation Buttons */}
                            <div className="flex justify-between mt-6">
                                {currentStep > 2 && (
                                    <button
                                        type="button"
                                        onClick={() => setCurrentStep(currentStep - 1)}
                                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                                    >
                                        Previous
                                    </button>
                                )}
                                {currentStep < totalSteps && (
                                    <button
                                        type="button"
                                        onClick={() => setCurrentStep(currentStep + 1)}
                                        className="ml-auto px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                                        disabled={
                                            (currentStep === 2 && !language) ||
                                            (currentStep === 3 && !speakerName)
                                        }
                                    >
                                        Next
                                    </button>
                                )}
                                {currentStep === totalSteps && (
                                    <button
                                        type="submit"
                                        className="ml-auto px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                                        disabled={!originalFile}
                                    >
                                        Create
                                    </button>
                                )}
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </section>
    );
}
