// VideoTasksHistory.jsx
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import Home_Navbar from "../components/Home_Navbar";
import Sidebar from "../components/Sidebar";
import SystemError from "../components/SystemError";
import { BASE_URL } from "../function/Config";

export default function VideoTasksHistory() {
    const history = useHistory();
    const { userId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [videoTasks, setVideoTasks] = useState([]);

    const fetchVideoTasks = () => {
        setIsLoading(true);
        const token = sessionStorage.getItem('Token');
        const url = `${BASE_URL}/video-tasks/${userId}/all`;

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setVideoTasks(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Fetch error:', error);
                setShowModal(true);
                setIsLoading(false);
            });
    };

    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString('en-CA');
    };

    useEffect(() => {
        fetchVideoTasks();
    }, []);

    return (
        <section>
            <Home_Navbar />
            <Sidebar />
            {showModal && <SystemError onClose={() => setShowModal(false)} />}
            <div className="p-4 sm:ml-64">
                <div className="flex flex-wrap justify-center items-center mt-20">
                    <div className="relative overflow-x-auto shadow-md w-full max-w-4xl mx-auto">
                        <h2 className="text-2xl font-bold mb-4 text-center">Lecture Video Tasks History</h2>
                        <table className="w-full text-sm text-center text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Voice Model</th>
                                    <th scope="col" className="px-6 py-3">Slides</th>
                                    <th scope="col" className="px-6 py-3">Talking-head Model</th>
                                    <th scope="col" className="px-6 py-3">Status</th>
                                    <th scope="col" className="px-6 py-3">Download</th>
                                    <th scope="col" className="px-6 py-3">Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {videoTasks.map(task => (
                                    <tr key={task.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <td className="px-6 py-4">{task.speaker}</td>
                                        <td className="px-6 py-4">{task.slideName}</td>
                                        <td className="px-6 py-4">{task.videoName}</td>
                                        <td className="px-6 py-4">
                                            <span className={`font-medium ${task.status === 'done' ? 'text-green-600' : 'text-red-600'}`}>
                                                {task.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4">
                                            {task.filepath ? (
                                                <a
                                                    href={`${BASE_URL}/video-tasks/${task.id}`}
                                                    className="text-blue-500 hover:text-blue-600"
                                                    download
                                                >
                                                    Download
                                                </a>
                                            ) : (
                                                <span className="text-gray-400">Download not available</span>
                                            )}
                                        </td>
                                        <td className="px-6 py-4">{formatDate(task.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {isLoading && (
                            <div className="text-center mt-4">Loading...</div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
