import "../index.css"
import {useHistory, useParams} from "react-router-dom";

export default function Home_Navbar() {
    const history = useHistory();
    const params = useParams();

    const handleClick = () => {
        if (history.location.pathname === '/signin') {
            history.push(`/signup`);
        } else if (history.location.pathname === '/signup' || history.location.pathname === '/') {
            history.push(`/signin`);
        } else {
            history.push(`/`);
        }
    };

    const buttonText = history.location.pathname === '/signin' ? 'Sign up' :
        history.location.pathname === '/signup' ? 'Sign in' :
            history.location.pathname === '/' || history.location.pathname === '/about' || history.location.pathname === '/contact' ? 'Sign in' : 'Log out';


    const logoHref = history.location.pathname === '/signin' || history.location.pathname === '/signup' || history.location.pathname === '/'
        ? '/' : `/dashboard/${params.userId}`;


    return (
        <section>
            <nav
                className="fixed z-20 bg-white dark:bg-gray-900  w-full top-0 left-0 border-b border-gray-200 dark:border-gray-600">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href={logoHref} className="flex items-center">
                        <img src="/logo_black.png" className="h-12 mr-3" alt="Sregen.Ai Logo" />
                        {/* <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Sregen.AI</span> */}
                    </a>
                    <div className="flex md:order-2">
                        <button onClick={handleClick} type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            {buttonText}
                        </button>

                        <button data-collapse-toggle="navbar-sticky" type="button"
                                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                aria-controls="navbar-sticky" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 17 14">
                                <path stroke="currentColor" d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                        </button>
                    </div>
                    <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
                         id="navbar-sticky">
                        <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <a href="/"
                                   className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                                   aria-current="page">Home</a>
                            </li>
                            <li>
                                <a href="https://sregen.ai/"
                                   className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About Us</a>
                            </li>
                            <li>
                                <a href="https://forms.gle/3UTm1LumCs2AXeJc9"
                                   className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Feedback</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    )
}